import * as React from 'react'

import { i18n, useSiteDefinition } from '@thg-commerce/enterprise-core'
import { Image, ImageProps, SectionTitle } from '@thg-commerce/gravity-elements'
import {
  Carousel,
  CarouselButtonPlacement,
} from '@thg-commerce/gravity-patterns'
import {
  AmpCarouselProps,
  CarouselI18nText,
} from '@thg-commerce/gravity-patterns/Carousel/types'
import { spacing, styled, Text } from '@thg-commerce/gravity-theme'

import { buildImageSrc } from '../../utils'

export interface Author {
  image: string
  imageTitle: string
  link: string
  name: string
  subHeader: string
  userNameURI: string
}

export interface AuthorCarouselRendererProps {
  contentRoutePrefix?: string
  authors: Author[]
  isAmp: boolean
}

const AuthorLink = styled.a`
  display: block;
  padding-top: ${spacing(4)};
  text-align: center;
  text-decoration: none;
`

const StyledImage = styled(Image)`
  width: 100px;
  height: 100px;
  background-color: transparent;
  border-radius: 100%;
`

const AuthorName = styled.h4`
  ${Text('bodyText', 'alternate')}
  padding-top: ${spacing(2)};
  text-align: center;
  font-weight: bold;
`

const AuthorTitle = styled.h5`
  ${Text('small', 'default')}
  text-decoration: none;
  text-align: center;
`

const buildAuthorUrl = (
  authorUrl: string,
  contentRoutePrefix: string,
): string => {
  if (contentRoutePrefix && contentRoutePrefix[0] !== '/') {
    return `${'/'.concat(contentRoutePrefix)}${authorUrl}`
  }
  return `${contentRoutePrefix}${authorUrl}`
}

export const AuthorCarouselRenderer = ({
  contentRoutePrefix,
  authors,
  isAmp,
}: AuthorCarouselRendererProps) => {
  const carouselText: CarouselI18nText = {
    leftScrollLabel: i18n('carousel.controls.left.arialabel'),
    rightScrollLabel: i18n('carousel.controls.right.arialabel'),
  }
  const { imageServiceURL } = useSiteDefinition()

  const componentTitle = i18n('content.authorcarousel.description')

  if (authors.length < 1) return null

  const largeOrMediumBreakPointAuthors = authors.length < 5 ? authors.length : 5
  const smallBreakPointAuthors = authors.length < 2 ? authors.length : 2

  const ampProps: AmpCarouselProps = {
    height: 150,
    width: 150,
    type: 'slides',
    role: 'region',
  }

  const authorComponents = authors.map((author: Author, index: number) => {
    const authorURL = author.link.substring(author.link.indexOf('/author'))

    const images: ImageProps['src'] = [
      {
        url: buildImageSrc(author.image, 200, imageServiceURL),
      },
    ]
    const carouselImage: ImageProps = {
      src: images,
      alt: `${author.name} ${author.subHeader}`,
      isAmp: false,
      height: '100px',
      width: '100%',
      imageStyle: 'contain',
    }

    return (
      <React.Fragment>
        <AuthorLink href={buildAuthorUrl(authorURL, contentRoutePrefix || '')}>
          <StyledImage {...carouselImage} key={index} />
          <AuthorName aria-label={author.name}>{author.name}</AuthorName>
        </AuthorLink>
        <AuthorTitle aria-label={author.subHeader}>
          {author.subHeader}
        </AuthorTitle>
      </React.Fragment>
    )
  })

  return (
    <React.Fragment>
      <SectionTitle>{componentTitle}</SectionTitle>
      <Carousel
        data-testid={'author-carousel-element'}
        items={authorComponents}
        itemsPerSlide={[
          1,
          smallBreakPointAuthors,
          largeOrMediumBreakPointAuthors,
          largeOrMediumBreakPointAuthors,
        ]}
        i18n={carouselText}
        isAmp={isAmp}
        ampProps={ampProps}
        hideSlidePreview={true}
        hideControlsOnSingleSlide={true}
        buttonPlacement={CarouselButtonPlacement.Split}
      />
    </React.Fragment>
  )
}
